.buttonNavbar {
    background-color: #397e3d;
    border: 1px solid #397e3d;
    border-radius: 6px;
    color: #fff !important;
    cursor: pointer;
    display: inline-block;
    font-size: 15px;
    font-weight: 500;
    line-height: 45px;
    padding: 0 30px;
    text-align: center;
    transition: all 0.4s ease-out 0s;
    -webkit-user-select: none;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    margin-top: 20px;
    margin-left: 10px;
  }
  .navLogo {
    float: left;
    margin-top: 6px;
    height: 70px;
    width: 180px;
    max-inline-size: 100%;
    block-size: auto;
  }
  .navLink {
    margin-top: 22px;
    color: #0e1133;
    font-size: 15px;
    font-weight: 500;
    line-height: 45px;
    li {
      padding: 0px 15px 0px 15px;
      a {
        color: #0e1133;
        text-decoration: none;
      }
    }
  }
  .logomobilediv {
    height: 100px;
    width: 250px;
  }
  .logomobile {
    margin-top: 43px;
    margin-left: 11px;
    height: 100%;
    width: 100%;
    max-inline-size: 100%;
    block-size: auto;
  }
  .linkMobile {
    border-bottom: 1px solid #ededed;
    color: #222;
    display: block;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
    padding-bottom: 10px;
    text-transform: capitalize;
    transition: all 0.3s ease-out 0s;
    a {
      color: #0e1133;
      text-decoration: none;
    }
  }
  
  .login_btn{
    background-color: #397e3d;
    text-decoration: none;
    border: 1px solid #397e3d;
    border-radius: 6px;
    color: #fff !important;
    cursor: pointer;
    display: inline-block;
    font-size: 15px;
    font-weight: 500;
    line-height: 45px;
    padding: 0 30px;
    text-align: center;
    transition: all 0.4s ease-out 0s;
    -webkit-user-select: none;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    margin-top: 20px;
    margin-left: 10px;
  }
  .sub_menu{
    background-color: #fff;
    border: 1px solid #ededed;
    margin-top: 10px;
    margin-left: 10px;
    border-radius: 0px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    padding: 10px 10px; 
    position: absolute;
    width: 180px;
    z-index: 999;
    li{
      padding: 3px 12px 3px 12px;
      list-style-type: none;
      span{
        color: #0e1133;
        text-decoration: none;
        transition: 0.3s;
        cursor: pointer;
      }
      :hover{
        background-color: transparent;
        color: #397e3d;
        padding-left: 10px;
      }
    }
  }