.wrapper {
  background-color: #397e3d;
  font-family: $headings-font-family;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  overflow: scroll;
}
.exampwrapperle::-webkit-scrollbar {
  display: none;
}
.wrapper {
  // -ms-overflow-style: none;
  // scrollbar-width: none;
}
.form_container {
  height: auto;
  width: 552px;
  padding: 50px;
  background-color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
  border-radius: 14px;
  margin: 50px 0px;
}
.title {
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 71px;
  color: #202020 !important;
  margin: 0px;
}

.title_description {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin: 0px 0px 40px 0px;
  color: #202020 !important;
}

.custom_input {
  font-family: $headings-font-family;
  background: transparent;
  width: 100%;
  padding: 0px 20px;
  height: 60px;
  font-size: 14px;
  border: 2px solid transparent;
  z-index: 1;
  color: gray;
  border-radius: 10px;
  transition: border 0.3s;
  caret-color: #397e3d;
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 600;
  border: 2px solid #f5f5f5;
  display: flex;
  align-items: center;
}
.custom_input[type='password'] {
  font: small-caption;
  font-size: 16px;
  background: transparent;
}
.custom_input:focus,
.custom_input:active {
  outline: none;
  border-color: #397e3d;
}

@media screen and (max-width: 600px) {
  .form_container {
    width: 412px;
  }
}
@media screen and (max-width: 475px) {
  .form_container {
    width: 365px;
    margin: 10px;
    padding: 30px;
  }
}
@media screen and (max-width: 399px) {
  .form_container {
    width: 300px;
    margin: 10px;
    padding: 20px;
  }
  .title {
    font-size: 30px;
  }
  .step_label {
    font-size: 16px;
  }
}

.helpImage {
  width: 100%;
  height: 100%;
}
.form_group_label_account {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 1px;
  color: #c7c7c7 !important;
  margin: 0px;
}
.forgot_password_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 42px;
}
.forgot_password_container svg {
  color: #397e3d !important;
}
.forgot_passwort_text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #202020 !important;
  margin: 0px;
}
.reset_password_text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: right;
  text-decoration-line: underline;
  color: #397e3d;
}
.stepperContainer {
  width: 100%;
  margin-bottom: 20px;
}
.step_label {
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 34px;
}
.stepper_complete_image {
  height: 20px;
  width: 20px;
  border-radius: 50%;
}
.stripe_input {
  width: 100%;
  padding: 0px 20px;
  height: 60px;
  border: 2px solid transparent;
  background: #f5f5f5;
  border-radius: 10px;
  font-size: 12px;
  transition: border 0.3s;
  color: #202020 !important;
  margin-bottom: 30px;
  caret-color: #397e3d;
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 14px;
  color: #202020 !important;
  margin: 8px 0px 10px 0px;
}
.stripe_input:focus,
.stripe_input:active {
  outline: none;
  border-color: #397e3d;
}
.remember__container {
  display: flex;
  align-items: center;
}
