.helpImage {
    width: 100%;
    height: 100%;
}

.helpButton {
    display: flex;
    align-self: center;
    width: 18px;
    height: 18px;
    border-radius: 9px;
    padding: 0px !important;
    margin-left: 5px !important;
}

.textfield {
    margin-top: 7px;
}

.form {
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 30px;
    border-radius: 15px;
}

.account {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 28%;
}

.account__title {
    text-align: center;
}

.account__forgot-password {
    text-align: center;
}

@media only screen and (max-width: 720px) {
    .account {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10%;
    }
}

@media only screen and (max-width: 600px) {
    .account {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 1%;
    }
}