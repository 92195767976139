.actionState {
  flex-direction: row;
  justify-content: flex-end !important;
  align-items: center !important;
  display: flex;
  height: 100%;
  padding-right: 15px;
}

.postBtn {
  margin-left: 15px !important;
  background-color: green;
}
.taskRow {
  width: 100% !important;
  margin-left: 0px;
}

.expSummary {
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin: 0 !important;
}
.expSummary div:first-child {
  margin: 0 !important;
}

.stateCard {
  padding-left: 0px !important;
  padding-right: 0px !important;
  background-color: transparent !important;
}

.cardCreate {
  margin-top: 25px;
  margin-left: 25px;
  margin-right: 25px;
}
.radioGroup {
  padding-bottom: 20px;
}
.radioCreate {
  margin-left: 20px !important;
}

.dropAreaFile {
  cursor: pointer;
  margin-bottom: 0;
}
.topText {
  flex: 1.5;
  padding-top: 15px;
}

.bottomText {
  flex: 1;
}

.MuiTableCell-paddingCheckbox:last-child {
  text-align: center;
  padding-left: 25px !important;
  padding-right: 0px !important;
}

.docType {
  padding-top: 15px !important;
}
.docsPending {
  justify-content: center;
  text-align: center;
}

.navLinkAuth:hover {
  text-decoration: none;
}

.stateScheduleCon {
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 30px;
}
.lastSend {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

@media (max-width: 599px) {
  .lastSend {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .lsSend {
    display: none;
  }
}

.stateStatus {
  display: flex;
  align-items: center;
  height: 100%;
}

.sendAll {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-right: 30px;
}

.empItem {
  cursor: default !important;
}

.badgeDoc {
  cursor: pointer;
}
.docName {
  display: flex;
}

.actionDocAuth {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.changeReqTextArea {
  width: 100%;
  padding: 30px;
}

.loaderForm {
  margin: auto !important;
  text-emphasis: center;
}
