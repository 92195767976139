.helpImage {
    width: 100%;
    height: 100%;
}

.helpButton {
    display: flex;
    align-self: center;
    width: 18px;
    height: 18px;
    border-radius: 9px;
    padding: 0px !important;
    margin-left: 5px !important;
}

.input-field {
    margin-top: 25px;
}

.card {
    margin-top: 30px;
    padding-left: 40px; 
    padding-right: 40px;
}